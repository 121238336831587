import useFetch from "../../useFetch";
const DevTo = () => {
  const { data, isPending, error } = useFetch(
    "https://shinytabworkers.bcoffield.workers.dev/rss/devto/"
  );

  return (
    <div>
      {data && !isPending ? (
        <div>
          <h4>DevTo</h4>
          {data.items.map((entry) => (
            <ul key={entry.pubDate}>
              <li>
                <a
                  className="linkToStyle"
                  href={entry.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entry.title}
                </a>
              </li>
            </ul>
          ))}
        </div>
      ) : (
        <div>Loading DevTo. . . </div>
      )}
    </div>
  );
};

export default DevTo;
