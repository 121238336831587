import BillingsWeather from "./components/billingsWeather";
import PinboardList from "./components/pinboardList";
import RssFeedList from "./components/rssFeedList"
import QuotesContainer from "./components/quotesContainer";

const Home = () => {
  
  return (
    <div>
      {/* <h1>Shinytab React 2022</h1> */}
      <QuotesContainer />
      <br />
      <BillingsWeather />
      <br />
      <PinboardList />
      <br />

      <RssFeedList />
    </div>
  );
};

export default Home;
