import useFetch from "../useFetch";

const BillingsWeather = () => {
  const sourceUrl = "https://shinytabworkers.bcoffield.workers.dev/weather/";
  const { data, isPending, error } = useFetch(sourceUrl);
  console.log("isPending:", isPending, "DATA: ", data);

  return (
    <div>
      {data && !isPending ? (
        <div>
          {/* <h2>WEATHER</h2> */}

          <div id="weatherBoxes">
            <div className="weatherBox">
              <span className="weatherTemp">
                {Math.round(data.current.temp)}
              </span>{" "}
              <br />
              <div className="weatherDescription">
                {data.current.weather[0].description} <br />
              </div>{" "}
              <span className="weatherDesignation">currently</span>
            </div>

            <div className="weatherBox">
              <span className="weatherTemp">
                {Math.round(data.daily[0].temp.max)} /{" "}
                {Math.round(data.daily[0].temp.min)}
              </span>{" "}
              <br />
              <div className="weatherDescription">
                {data.daily[0].weather[0].description} <br />
              </div>
              <span className="weatherDesignation">today</span>
            </div>

            <div className="weatherBox">
              <span className="weatherTemp">
                {" "}
                {Math.round(data.daily[1].temp.max)} /{" "}
                {Math.round(data.daily[1].temp.min)}
              </span>{" "}
        
              <div className="weatherDescription">
                {data.daily[1].weather[0].description} 
              </div> 
         
              <span className="weatherDesignation">tomorrow</span>
            </div>
          </div>
        </div>
      ) : (
        <div>Loading the weather. . .</div>
      )}
    </div>
  );
};

export default BillingsWeather;
