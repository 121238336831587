export const AlanWattsQuotes = [
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTrying to define yourself is like trying to bite your own teeth.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "buddhism",
      "metaphysics",
      "philosophy",
      "religion",
      "tao",
      "taoism",
      "theology",
      "zen",
    ],
    text: "\u201cMan suffers only because he takes seriously what the gods made for fun.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "civilization",
      "culture",
      "names",
      "power-of-words",
      "responsibility",
      "wisdom",
    ],
    text: "\u201cWe seldom realize, for example that our most private thoughts and emotions are not actually our own. For we think in terms of languages and images which we did not invent, but which were given to us by our society.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe meaning of life is just to be alive. It is so plain and so obvious and so simple. And yet, everybody rushes around in a great panic as if it were necessary to achieve something beyond themselves.\u201d    \u2015      Alan Wilson Watts,  The Culture of Counter-Culture: Edited Transcripts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["life", "play", "work"],
    text: "\u201cThis is the real secret of life -- to be completely engaged with what you are doing in the here and now. And instead of calling it work, realize it is play.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["process", "time"],
    text: "\u201cMuddy water is best cleared by leaving it alone.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["faith", "freedom", "trust"],
    text: "\u201cTo have faith is to trust yourself to the water. When you swim you don't grab hold of the water, because if you do you will sink and drown. Instead you relax, and float.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["writing"],
    text: "\u201cAdvice? I don\u2019t have advice. Stop aspiring and start writing. If you\u2019re writing, you\u2019re a writer. Write like you\u2019re a goddamn death row inmate and the governor is out of the country and there\u2019s no chance for a pardon. Write like you\u2019re clinging to the edge of a cliff, white knuckles, on your last breath, and you\u2019ve got just one last thing to say, like you\u2019re a bird flying over us and you can see everything, and please, for God\u2019s sake, tell us something that will save us from ourselves. Take a deep breath and tell us your deepest, darkest secret, so we can wipe our brow and know that we\u2019re not alone. Write like you have a message from the king. Or don\u2019t. Who knows, maybe you\u2019re one of the lucky ones who doesn\u2019t have to.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "change",
      "dance",
      "flow",
      "impermanence",
      "inspiration",
      "reality",
      "things",
      "world",
    ],
    text: "\u201cThe only way to make sense out of change is to plunge into it, move with it, and join the dance.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["buddhism", "metaphysical", "philosophy"],
    text: "\u201cYou are an aperture through which the universe is looking at and exploring itself.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "language",
      "metaphysics",
      "names",
      "nominalism",
      "philosophy",
      "power-of-words",
      "words",
    ],
    text: "\u201cThe menu is not the meal.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["self", "universe"],
    text: "\u201cYou are a function of what the whole universe is doing in the same way that a wave is a function of what the whole ocean is doing.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["harmony", "self", "universe"],
    text: "\u201cThrough our eyes, the universe is perceiving itself. Through our ears, the universe is listening to its harmonies. We are the witnesses through which the universe becomes conscious of its glory, of its magnificence.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["future", "illusion", "past", "present", "time"],
    text: "\u201cI have realized that the past and future are real illusions, that they exist in the present, which is what there is and all there is.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["awakening", "blasphemy", "god", "hinduism", "jesus-christ", "self"],
    text: "\u201cJesus Christ knew he was God. So wake up and find out eventually who you really are. In our culture, of course, they\u2019ll say you\u2019re crazy and you\u2019re blasphemous, and they\u2019ll either put you in jail or in a nut house (which is pretty much the same thing). However if you wake up in India and tell your friends and relations, \u2018My goodness, I\u2019ve just discovered that I\u2019m God,\u2019 they\u2019ll laugh and say, \u2018Oh, congratulations, at last you found out.\u201d    \u2015      Alan Wilson Watts,  The Essential Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe more a thing tends to be permanent, the more it tends to be lifeless.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["expectation", "memory", "names", "now", "present", "time"],
    text: "\u201cWe are living in a culture entirely hypnotized by the illusion of time, in which the so-called present moment is felt as nothing but an infintesimal hairline between an all-powerfully causative past and an absorbingly important future. We have no present. Our consciousness is almost completely preoccupied with memory and expectation. We do not realize that there never was, is, nor will be any other experience than present experience. We are therefore out of touch with reality. We confuse the world as talked about, described, and measured with the world which actually is. We are sick with a fascination for the useful tools of names and numbers, of symbols, signs, conceptions and ideas.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["life", "living"],
    text: "\u201cThe art of living... is neither careless drifting on the one hand nor fearful clinging to the past on the other. It consists in being sensitive to each moment, in regarding it as utterly new and unique, in having the mind open and wholly receptive.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTry to imagine what it will be like to go to sleep and never wake up... now try to imagine what it was like to wake up having never gone to sleep.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThings are as they are. Looking out into the universe at night, we make no comparisons between right and wrong stars, nor between well and badly arranged constellations.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["philosophy"],
    text: "\u201cProblems that remain persistently insoluble should always be suspected as questions asked in the wrong way.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTomorrow and plans for tomorrow can have no significance at all unless you are in full contact with the reality of the present, since it is in the present and only in the present that you live. There is no other reality than present reality, so that, even if one were to live for endless ages, to live for the future would be to miss the point everlastingly.\u201d    \u2015      Alan Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cA scholar tries to learn something everyday; a student of Buddhism tries to unlearn something daily.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["wisdom"],
    text: "\u201cEvery intelligent individual wants to know what makes him tick, and yet is at once fascinated and frustrated by the fact that oneself is the most difficult of all things to know.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["zen"],
    text: "\u201cIt's like you took a bottle of ink and you threw it at a wall. Smash! And all that ink spread. And in the middle, it's dense, isn't it? And as it gets out on the edge, the little droplets get finer and finer and make more complicated patterns, see? So in the same way, there was a big bang at the beginning of things and it spread. And you and I, sitting here in this room, as complicated human beings, are way, way out on the fringe of that bang. We are the complicated little patterns on the end of it. Very interesting. But so we define ourselves as being only that. If you think that you are only inside your skin, you define yourself as one very complicated little curlique, way out on the edge of that explosion. Way out in space, and way out in time. Billions of years ago, you were a big bang, but now you're a complicated human being. And then we cut ourselves off, and don't feel that we're still the big bang. But you are. Depends how you define yourself. You are actually--if this is the way things started, if there was a big bang in the beginning-- you're not something that's a result of the big bang. You're not something that is a sort of puppet on the end of the process. You are still the process. You are the big bang, the original force of the universe, coming on as whoever you are. When I meet you, I see not just what you define yourself as--Mr so-and- so, Ms so-and-so, Mrs so-and-so--I see every one of you as the primordial energy of the universe coming on at me in this particular way. I know I'm that, too. But we've learned to define ourselves as separate from it. \u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["process", "tao"],
    text: '\u201cWe do not "come into" this world; we come out of it, as leaves from a tree. As the ocean "waves," the universe "peoples." Every individual is an expression of the whole realm of nature, a unique action of the total universe.\u201d    \u2015      Alan Wilson Watts',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cLife is like music for its own sake. We are living in an eternal now, and when we listen to music we are not listening to the past, we are not listening to the future, we are listening to an expanded present.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["feigned-affection"],
    text: "\u201cNever pretend to a love which you do not actually feel, for love is not ours to command.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhen we attempt to exercise power or control over someone else, we cannot avoid giving that person the very same power or control over us.\u201d    \u2015      Alan Wilson Watts,  The Way of Zen",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["concepts", "philosophy", "theory", "words"],
    text: '\u201cWhat we have forgotten is that thoughts and words are conventions, and that it is fatal to take conventions too seriously. A convention is a social convenience, as, for example, money ... but it is absurd to take money too seriously, to confuse it with real wealth ... In somewhat the same way, thoughts, ideas and words are "coins" for real things.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cZen does not confuse spirituality with thinking about God while one is peeling potatoes. Zen spirituality is just to peel the potatoes.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["career", "dream", "life", "money", "time", "wasting", "work"],
    text: "\u201cIf you say that getting the money is the most important thing, you'll spend your life completely wasting your time. You'll be doing things you don't like doing in order to go on living, that is to go on doing thing you don't like doing, which is stupid.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTo remain stable is to refrain from trying to separate yourself from a pain because you know that you cannot. Running away from fear is fear, fighting pain is pain, trying to be brave is being scared. If the mind is in pain, the mind is pain. The thinker has no other form than his thought. There is no escape.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["mindfulness", "psychology"],
    text: "\u201cOne is a great deal less anxious if one feels perfectly free to be anxious, and the same may be said of guilt.\u201d    \u2015      Alan Wilson Watts,  Psychotherapy East and West",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cLet's suppose that you were able every night to dream any dream that you wanted to dream. And that you could, for example, have the power within one night to dream 75 years of time. Or any length of time you wanted to have. And you would, naturally as you began on this adventure of dreams, you would fulfill all your wishes. You would have every kind of pleasure you could conceive. And after several nights of 75 years of total pleasure each, you would say \"Well, that was pretty great.\" But now let's have a surprise. Let's have a dream which isn't under control. Where something is gonna happen to me that I don't know what it's going to be. And you would dig that and come out of that and say \"Wow, that was a close shave, wasn't it?\" And then you would get more and more adventurous, and you would make further and further out gambles as to what you would dream. And finally, you would dream ... where you are now. You would dream the dream of living the life that you are actually living today.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["oneness", "universe"],
    text: "\u201cYou and I are all as much continuous with the physical universe as a wave is continuous with the ocean.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["meaning", "meaninglessness", "the-way-of-liberation"],
    text: "\u201cAnd people get all fouled up because they want the world to have meaning as if it were words... As if you had a meaning, as if you were a mere word, as if you were something that could be looked up in a dictionary. You are meaning.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIf we cling to belief in God, we cannot likewise have faith, since faith is not clinging but letting go.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cHow is it possible that a being with such sensitive jewels as the eyes, such enchanted musical instruments as the ears, and such fabulous arabesque of nerves as the brain can experience itself anything less than a god.\u201d    \u2015      Alan Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTo put is still more plainly: the desire for security and the feeling of insecurity are the same thing. To hold your breath is to lose your breath. A society based on the quest for security is nothing but a breath-retention contest in which everyone is as taut as a drum and as purple as a beet.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "eqaility",
      "faith",
      "freedom",
      "laughter",
      "mysticism",
      "religion",
      "spirituality",
    ],
    text: "\u201cA priest once quoted to me the Roman saying that a religion is dead when the priests laugh at each other across the altar. I always laugh at the altar, be it Christian, Hindu, or Buddhist, because real religion is the transformation of anxiety into laughter.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhat I am really saying is that you don\u2019t need to do anything, because if you see yourself in the correct way, you are all as much extraordinary phenomenon of nature as trees, clouds, the patterns in running water, the flickering of fire, the arrangement of the stars, and the form of a galaxy. You are all just like that, and there is nothing wrong with you at all.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cBut I'll tell you what hermits realize. If you go off into a far, far forest and get very quiet, you'll come to understand that you're connected with everything.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["enviornment", "self"],
    text: "\u201cOther people teach us who we are. Their attitudes to us are the mirror in which we learn to see ourselves, but the mirror is distorted. We are, perhaps, rather dimly aware of the immense power of our social enviornment.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["love", "sex", "zen"],
    text: "\u201cThe world is filled with love-play, from animal lust to sublime compassion.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["social-learning", "society"],
    text: "\u201cWe seldom realize, for example, that our most private thoughts and emotions are not actually our own. For we think in terms of languages and images which we did not invent, but which were given to us by our society. We copy emotional reactions from our parents, learning from them thatexcrement is supposed to have a disgusting smell and that vomiting is supposed to be an unpleasant sensation. The dread of death is also learned from their anxieties about sickness and from their attitudes to funerals and corpses. Our social environment has this power just because we do not exist apart from a society. Society is our extended mind and body. Yet the very society from which the individual is inseparable is using its whole irresistible force to persuade the individual that he is indeed separate! Society as we now know it is therefore playing a game with self-contradictory rules.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["illusion-of-self", "love", "self", "self-love"],
    text: "\u201cSo then, the relationship of self to other is the complete realization that loving yourself is impossible without loving everything defined as other than yourself.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["definition"],
    text: "\u201cWe could say that meditation doesn't have a reason or doesn't have a purpose. In this respect it's unlike almost all other things we do except perhaps making music and dancing. When we make music we don't do it in order to reach a certain point, such as the end of the composition. If that were the purpose of music then obviously the fastest players would be the best. Also, when we are dancing we are not aiming to arrive at a particular place on the floor as in a journey. When we dance, the journey itself is the point, as when we play music the playing itself is the point. And exactly the same thing is true in meditation. Meditation is the discovery that the point of life is always arrived at in the immediate moment.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIrrevocable commitment to any religion is not only intellectual suicide; it is positive unfaith because it closes the mind to any new vision of the world. Faith is, above all, openness - an act of trust in the unknown.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhat we have to discover is that there is no safety, that seeking is painful, and that when we imagine that we have found it, we don\u2019t like it.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["poetry", "power-of-words"],
    text: "\u201cThere is nothing at all that can be talked about adequately, and the whole art of poetry is to say what can't be said.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIf, then, my awareness of the past and future makes me less aware of the present, I must begin to wonder whether I am actually living in the real world.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cReal travel requires a maximum of unscheduled wandering, for there is no other way of discovering surprises and marvels, which, as I see it, is the only good reason for not staying at home.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Mark  Manson,",
    image: "https://images.gr-assets.com/authors/1410365708p8/8529755.jpg",
    tags: [],
    text: "\u201cBecause here\u2019s the thing that\u2019s wrong with all of the \u201cHow to Be Happy\u201d shit that\u2019s been shared eight million times on Facebook in the past few years\u2014here\u2019s what nobody realizes about all of this crap: The desire for more positive experience is itself a negative experience. And, paradoxically, the acceptance of one\u2019s negative experience is itself a positive experience. This is a total mind-fuck. So I\u2019ll give you a minute to unpretzel your brain and maybe read that again: Wanting positive experience is a negative experience; accepting negative experience is a positive experience. It\u2019s what the philosopher Alan Watts used to refer to as \u201cthe backwards law\u201d\u2014the idea that the more you pursue feeling better all the time, the less satisfied you become, as pursuing something only reinforces the fact that you lack it in the first place. The\u201d    \u2015      Mark  Manson,  The Subtle Art of Not Giving a F*ck: A Counterintuitive Approach to Living a Good Life",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["definition"],
    text: "\u201cReally, the fundamental, ultimate mystery -- the only thing you need to know to understand the deepest metaphysical secrets -- is this: that for every outside there is an inside and for every inside there is an outside, and although they are different, they go together.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cThis, then, is the human problem: there is a price to be paid for every increase in consciousness. We cannot be more sensitive to pleasure without being more sensitive to pain. By remembering the past we can plan for the future. But the ability to plan for the future is offset by the "ability" to dread pain and to fear of the unknown. Furthermore, the growth of an acute sense of the past and future gives us a corresponding dim sense of the present. In other words, we seem to reach a point where the advantages of being conscious are outweighed by its disadvantages, where extreme sensitivity makes us unadaptable.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["flickr"],
    text: "\u201cYou're under no obligation to be the same person you were 5 minutes ago.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cFor unless one is able to live fully in the present, the future is a hoax.There is no point whatever in making plans for a future which you willnever be able to enjoy. When your plans mature, you will still be livingfor some other future beyond. You will never, never be able to sit backwith full contentment and say, "Now, I\'ve arrived!" Your entireeducation has deprived you of this capacity because it was preparingyou for the future, instead of showing you how to be alive now.\u201d    \u2015      Alan Wilson Watts',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cParadoxical as it may seem, the purposeful life has no content, no point. It hurries on and on, and misses everything. Not hurrying, the purposeless life misses nothing, for it is only when there is no goal and no rush that the human senses are fully open to receive the world.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["trust"],
    text: "\u201cIf you cannot trust yourself, you cannot even trust your mistrust of yourself - so that without this underlying trust in the whole system of nature you are simply paralyzed\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201c...[W]ords can be communicative only between those who share similar experiences.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cI am what happens between the maternity ward and the Crematorium\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cFor man seems to be unable to live without myth, without the belief that the routine and drudgery, the pain and fear of this life have some meaning and goal in the future. At once new myths come into being \u2013 political and economic myths with extravagant promises of the best of futures in the present world. These myths give the individual a certain sense of meaning by making him part of a vast social effort, in which he loses something of his own emptiness and loneliness. Yet the very violence of these political religions betrays the anxiety beneath them \u2013 for they are but men huddling together and shouting to give themselves courage in the dark.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe clash between science and religion has not shown that religion is false and science is true. It has shown that all systems of definition are relative to various purposes, and that none of them actually \u201cgrasp\u201d reality.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWe feel that our actions are voluntary when they follow a decision and involuntary when they happen without decision. But if a decision itself were voluntary every decision would have to be preceded by a decision to decide - An infinite regression which fortunately does not occur. Oddly enough, if we had to decide to decide, we would not be free to decide\u201d    \u2015      Alan Wilson Watts,  The Way of Zen",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["alchochol", "self", "self-conciousness", "suffering"],
    text: "\u201cLike too much alcohol,self-consciousness makes us see ourselves double, and we make the double image for two selves - mental and material, controlling and controlled, reflective and spontaneous. Thus instead of suffering we suffer about suffering, and suffer about suffering about suffering.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["poem"],
    text: "\u201cThere was a young man who said though, it seems that I know that I know, but what I would like to see is the I that knows me when I know that I know that I know.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cNo one is more dangerously insane than one who is sane all the time: he is like a steel bridge without flexibility, and the order of his life is rigid and brittle.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cJust as true humor is laughter at oneself, true humanity is knowledgeof oneself.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["reality-philosophy-buddhism"],
    text: "\u201cBut my dear man, reality is only a Rorschach ink-blot, you know.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cWe do not "come into" this world; we come out of it, as leaves from a tree.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cYour body does not eliminate poisons by knowing their names. To try to control fear or depression or boredom by calling them names is to resort to superstition of trust in curses and invocations. It is so easy to see why this does not work. Obviously, we try to know, name, and define fear in order to make it \u201cobjective,\u201d that is, separate from \u201cI.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "being",
      "future",
      "past",
      "present",
      "reality",
      "way-of-zen",
      "zen",
    ],
    text: "\u201cZen is a liberation from time. For if we open our eyes and see clearly, it becomes obvious that there is no other time than this instant, and that the past and the future are abstractions without any concrete reality.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["oneness", "technology", "universe"],
    text: "\u201cTechnology is destructive only in the hands of people who do not realize that they are one and the same process as the universe. \u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cBut you cannot understand life and its mysteries as long as you try to grasp it. Indeed, you cannot grasp it, just as you cannot walk off with a river in a bucket. If you try to capture running water in a bucket, it is clear that you do not understand it and that you will always be disappointed, for in the bucket the water does not run. To \u201chave\u201d running water you must let go of it and let it run.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["medicine"],
    text: "\u201cHospitals should be arranged in such a way as to make being sick an interesting experience. One learns a great deal sometimes from being sick. \u201d    \u2015      Alan Wilson Watts,  The Essential Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIf the universe is meaningless, so is the statement that it is so. If this world is a vicious trap, so is its accuser, and the pot is calling the kettle black.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIt\u2019s better to have a short life that is full of what you like doing, than a long life spent in a miserable way.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cYou do not play a sonata in order to reach the final chord, and if the meanings of things were simply in ends, composers would write nothing but finales.\u201d    \u2015      Alan W. Watts,  Wisdom Of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cFor if you know what you want, and will be content with it, you can be trusted. But if you do not know, your desires are limitless and no one can tell how to deal with you. Nothing satisfies an individual incapable of enjoyment.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIndeed, one of the highest pleasures is to be more or less unconscious of one\u2019s own existence, to be absorbed in interesting sights, sounds, places, and people. Conversely, one of the greatest pains is to be self-conscious, to feel unabsorbed and cut off from the community and the surrounding world.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cI find that the sensation of myself as an ego inside a bag of skin is really a hallucination.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cThis state of affairs is known technically as the "double-bind." Aperson is put in a double-bind by a command or request which containsa concealed contradiction...This is a damned-if-you-do and damned-if-you-don\'tsituation which arises constantly in human (and especially family)relations...The social doublebind game can be phrased in several ways:The first rule of this game is that it is not a game.Everyone must play.You must love us.You must go on living.Be yourself, but play a consistent and acceptable role.Control yourself and be natural.Try to be sincere.Essentially, this game is a demand for spontaneous behavior of certainkinds. Living, loving, being natural or sincere\u2014all these arespontaneous forms of behavior: they happen "of themselves" likedigesting food or growing hair. As soon as they are forced they acquirethat unnatural, contrived, and phony atmosphere which everyonedeplores\u2014weak and scentless like forced flowers and tasteless likeforced fruit. Life and love generate effort, but effort will not generatethem. Faith\u2014in life, in other people, and in oneself\u2014is the attitude ofallowing the spontaneous to be spontaneous, in its own way and in itsown time.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["creation", "god", "play"],
    text: "\u201cIt is interesting that Hindus, when they speak of the creation of the universe do not call it the work of God, they call it the play of God, the Vishnu lila, lila meaning play. And they look upon the whole manifestation of all the universes as a play, as a sport, as a kind of dance \u2014 lila perhaps being somewhat related to our word lilt\u201d    \u2015      Alan Watts,  Zen and the Beat Way",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cPeace can be made only by those who are peaceful, and love can be shown only by those who love. No work of love will flourish out of guilt, fear, or hollowness of heart, just as no valid plans for the future can be made by those who have no capacity for living now.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["changing", "meaning", "past", "present"],
    text: "\u201cWe must abandon completely the notion of blaming the past for any kind of situation we're in and reverse our thinking and see that the past always flows back form the present. That now is the creative point of life. So you see its like the idea of forgiving somebody, you change the meaning of the past by doing that...Also watch the flow of music. The melody as its expressed is changed by notes that come later. Just as the meaning of a sentence...you wait till later to find out what the sentence means...The present is always changing the past.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Huston Smith",
    image: "https://images.gr-assets.com/authors/1206303970p8/6734.jpg",
    tags: ["love"],
    text: "\u201cLove is the movement within life that carries us, that enables us, that causes us to break out of what Alan Watts calls the \u201cskin-encapsulated ego.\u201d Without love, we are self-centered, but love enables us to move the center of our lives outside our ego. Therefore it expands our lives and, needless to say, enriches it. Any human being would give anything to love or be loved. When it really happens, it is like heaven on earth.\u201d    \u2015      Huston Smith",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIf happiness always depends on something expected in the future, we are chasing a will-o\u2019-the-wisp that ever eludes our grasp, until the future, and ourselves, vanish into the abyss of death.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cAlthough the rhythm of the waves beats a kind of time, it is not clock or calendar time. It has no urgency. It happens to be timeless time. I know that I am listening to a rhythm which has been just the same for millions of years, and it takes me out of a world of relentlessly ticking clocks. Clocks for some reason or other always seem to be marching, and, as with armies, marching is never to anything but doom. But in the motion of waves there is no marching rhythm. It harmonizes with our very breathing. It does not count our days. Its pulse is not in the stingy spirit of measuring, of marking out how much still remains. It is the breathing of eternity, like the God Brahma of Indian mythology inhaling and exhaling, manifesting and dissolving the worlds, forever. As a mere conception this might sound appallingly monotonous, until you come to listen to the breaking and washing of waves.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cYou have seen that the universe is at root amagical illusion and a fabulous game, and that there is no separate"you" to get something out of it, as if life were a bank to be robbed. Theonly real "you" is the one that comes and goes, manifests and withdrawsitself eternally in and as every conscious being. For "you" is theuniverse looking at itself from billions of points of view, points thatcome and go so that the vision is forever new.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cIf you get the message, hang up the phone. For psychedelic drugs are simply instruments, like microscopes, telescopes, and telephones. The biologist does not sit with eye permanently glued to the microscope, he goes away and works on what he has seen.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhere there is to be creative action, it is quite beside the point to discuss what we should or should not do in order to be right or good. A mind that is single and sincere is not interested in being good, in conducting relations with other people so as to live up to a rule. Nor, on the other hand, is it interested in being free, in acting perversely just to prove its independence. Its interest is not in itself, but in the people and problems of which it is aware; these are \u201citself.\u201d It acts, not according to the rules, but according to the circumstances of the moment, and the \u201cwell\u201d it wishes to others is not security but liberty.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["atheism", "religion"],
    text: "\u201cJesus was not the man he was as a result of making Jesus Christ his personal savior.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["light", "nature", "observation", "relativity"],
    text: "\u201cThe source of all light is in the eye.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cYou are that vast thing that you see far, far off with great telescopes.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["silliness", "spiritual"],
    text: "\u201cPeople become concerned with being more humble than other people.\u201d    \u2015      Alan Watts,  Still the Mind: An Introduction to Meditation",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhen we dance, the journey itself is the point, as when we play music the playing itself is the point. And exactly the same thing is true in meditation. Meditation is the discovery that the point of life is always arrived at in the immediate moment.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["discovery", "silence"],
    text: "\u201cTo look at life without words is not to lose the ability to form words- to think, remember, and plan. To be silent is not to lose your tongue. On the contrary, it is only through silence that one can discover something new to talk about. One who talked incessantly, without stopping to look and listen, would repeat himself ad nauseam. It is the same with thinking, which is really silent talking. It is not, by itself, open to the discovery of anything new, for its only novelties are simply arrangements of old words and ideas.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cthe future is a concept\u2014it doesn\u2019t exist\ufeff There is no such thing as tomorrow There never will be because time is always now. That\u2019s one of the things we discover when we stop talking to ourselves and stop thinking. We find there is only present, only an eternal now.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["people", "solitude"],
    text: "\u201cI owe my solitude to other people.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["concentration", "world"],
    text: "\u201cNormally, we do not so much look at things as overlook them.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["life", "philosophy"],
    text: "\u201cIn reality there are no separate events. Life moves along like water, it's all connected to the source of the river is connected to the mouth and the ocean.\u201d    \u2015      Alan Wilson Watts,  The Essential Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cBut the transformation of consciousness undertaken in Taoism and Zen is more like the correction of faulty perception or the curing of a disease. It is not an acquisitive process of learning more and more facts or greater and greater skills, but rather an unlearning of wrong habits and opinions. As Lao-tzu said, "The scholar gains every day, but the Taoist loses every day.\u201d    \u2015      Alan Wilson Watts,  The Joyous Cosmology: Adventures in the Chemistry of Consciousness',
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["paradox", "philosophy", "power-of-words"],
    text: "\u201cPhilosophers, for example, often fail to recognize that their remarks about the universe apply also to themselves and their remarks. If the universe is meaningless, so is the statement that it is so.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe agnostic, the skeptic, is neurotic, but this does not imply a false philosophy; it implies the discovery of facts to which he does not know how to adapt himself. The intellectual who tries to escape from neurosis by escaping from the facts is merely acting on the principle that \u201cwhere ignorance is bliss, \u2018tis folly to be wise.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["eastern-philosophy", "esoterism", "meditation", "philosophy"],
    text: "\u201cwhen somebody plays music, you listen. you just follow those sounds, and eventually you understand the music. the point can't be explained in words because music is not words, but after listening for a while, you understand the point of it, and that point is the music itself. in exactly the same way, you can listen to all experiences.\u201d    \u2015      Alan Wilson Watts,  Still the Mind: An Introduction to Meditation",
  },
  {
    author: "Robert Anton Wilson,",
    image: "https://images.gr-assets.com/authors/1196008603p8/2918.jpg",
    tags: ["concepts", "knowledge", "philosophy", "science"],
    text: '\u201cThe Copenhagen Interpretation is sometimes called "model agnosticism" and holds that any grid we use to organize our experience of the world is a model of the world and should not be confused with the world itself. Alfred Korzybski, the semanticist, tried to popularize this outside physics with the slogan, "The map is not the territory." Alan Watts, a talented exegete of Oriental philosophy, restated it more vividly as "The menu is not the meal.\u201d    \u2015      Robert Anton Wilson,  Cosmic Trigger: Die letzten Geheimnisse der Illuminaten oder An den Grenzen des erweiterten Bewusstseins',
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["death", "fear"],
    text: '\u201cSuppressing the fear of death makes it all the stronger. The point is only to know, beyond any shadow of doubt, that "I" and all other "things" now present will vanish, until this knowledge compels you to release them - to know it now as surely as if you had just fallen off the rim of the Grand Canyon. Indeed you were kicked off the edge of a precipice when you were born, and it\'s no help to cling to the rocks falling with you. If you are afraid of death, be afraid. The point is to get with it, to let it take over - fear, ghosts, pains, transience, dissolution, and all. And then comes the hitherto unbelievable surprise; you don\'t die because you were never born. You had just forgotten who you are.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["love"],
    text: "\u201cWe know that from time to time, there arise among human beings, people who seem to exude love as naturally as the sun gives out heat.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["life"],
    text: "\u201cThe morning glory which blooms for an hour differs not at heart from the giant pine, which lives for a thousand years.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201ca man does not really begin to be alive until he has lost himself, until he has released the anxious grasp which he normally holds upon his life, his property, his reputation and position.\u201d    \u2015      Alan W. Watts,  Become What You Are",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cInability to accept the mystic experience is more than an intellectual handicap. Lack of awareness of the basic unity of organism and environment is a serious and dangerous hallucination. For in a civilization equipped with immense technological power, the sense of alienation between man and nature leads to the use of technology in a hostile spirit\u2014-to the \u201cconquest\u201d of nature instead of intelligent co-operation with nature.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cMost of us assume as a matter of common sense that space is nothing, that it's not important and has no energy. But as a matter of fact, space is the basis of existence. How could you have stars without space? Stars shine out of space and something comes out of nothing just in the same way as when you listen, in an unprejudiced way, you hear all sounds coming out of silence. It is amazing. Silence is the origin of sound just as space is the origin of stars, and woman is the origin of man. If you listen and pay close attention to what is, you will discover that there is no past, no future, and no one listening. You cannot hear yourself listening. You live in the eternal now and you are that. It is rally extremely simple, and that is the way it is.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWe thought of life by analogy with a journey, a pilgrimage, which had a serious purpose at the end, and the thing was to get to that end, success or whatever it is, maybe heaven after you\u2019re dead. But we missed the point the whole way along. It was a musical thing and you were supposed to sing or to dance while the music was being played.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["love", "principles", "truth"],
    text: "\u201cLike love, the light or guidance of truth that influences us exists only in living form, not in principles or rules or expectations or advice, however widely circulated\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["zen"],
    text: "\u201cFor there is never anything but the present, and if one cannot live there, one cannot live anywhere.\u201d    \u2015      Alan W. Watts,  The Way of Zen",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["reality", "universe", "world"],
    text: "\u201cIn looking out upon the world, we forget that the world is looking at itself.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe more we try to live in the world of words, the more we feel isolated and alone, the more all the joy and liveliness of things is exchanged for mere certainty and security. On the other hand, the more we are forced to admit that we actually live in the real world, the more we feel ignorant, uncertain, and insecure about everything.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThere are, then, two ways of understanding an experience. The first is to compare it with the memories of other experiences, and so to name and define it. This is to interpret it in accordance with the dead and the past. The second is to be aware of it as it is, as when, in the intensity of joy, we forget past and future, let the present be all, and thus do not even stop to think, \u201cI am happy.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["capitalism", "material", "pleasure", "stuff"],
    text: "\u201cOur pleasures are not material pleasures, but symbols of pleasure \u2013 attractively packaged but inferior in content.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cYou didn't come into this world. You came out of it, like a wave from the ocean. You are not a stranger here.\u201d    \u2015      Alan W. Watts,  Cloud-Hidden, Whereabouts Unknown",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cNo one imagines that a symphony is supposed to improve as it goes along, or that the whole object of playing is to reach the finale. The point of music is discovered in every moment of playing and listening to it. It is the same, I feel, with the greater part of our lives, and if we are unduly absorbed in improving them we may forget altogether to live them.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["meditation", "nominalism", "reality", "symbols"],
    text: "\u201cThe art of meditation is a way of getting into touch with reality, and the reason for it is that most civilized people are out of touch with reality because they confuse the world as it with the world as they think about it and talk about it and describe it. For on the one hand there is the real world and on the other there is a whole system of symbols about that world which we have in our minds. These are very very useful symbols, all civilization depends on them, but like all good things they have their disadvantages, and the principle disadvantage of symbols is that we confuse them with reality, just as we confuse money with actual wealth.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cJust as money is not real, consumable wealth, books are not life. To idolize scriptures is like eating paper currency.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cAs it is, we are merely bolting our lives\u2014gulping down undigested experiences as fast as we can stuff them in\u2014because awareness of our own existence is so superficial and so narrow that nothing seems to us more boring than simple being. If I ask you what you did, saw, heard, smelled, touched and tasted yesterday, I am likely to get nothing more than the thin, sketchy outline of the few things that you noticed, and of those only what you thought worth remembering. Is it surprising that an existence so experienced seems so empty and bare that its hunger for an infinite future is insatiable? But suppose you could answer, \u201cIt would take me forever to tell you, and I am much too interested in what\u2019s happening now.\u201d How is it possible that a being with such sensitive jewels as the eyes, such enchanted musical instruments as the ears, and such a fabulous arabesque of nerves as the brain can experience itself as anything less than a god? And, when you consider that this incalculably subtle organism is inseparable from the still more marvelous patterns of its environment\u2014from the minutest electrical designs to the whole company of the galaxies\u2014how is it conceivable that this incarnation of all eternity can be bored with being?\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["language", "power-of-words", "reality", "translation"],
    text: "\u201cIt is hard indeed to notice anything for which the languages available to us have no description.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThere is only this now. It does not come from anywhere; it is not going anywhere. It is not permanent, but it is not impermanent. Though moving, it is always still. When we try to catch it, it seems to run away, and yet it is always here and there is no escape from it. And when we turn around to find the self which knows this moment, we find that it has vanished like the past.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cFor there is a growing apprehension that existence is a rat-race in a trap: living organisms, including people,are merely tubes which put things in at one end and let them out at the other, which both keeps them doing it and in the long run wears them out. So to keep the farce going, the tubes find ways of making newtubes, which also put things in at one end and let them out at the other. At the input end they even develop ganglia of nerves called brains, with eyes and ears, so that they can more easily scrounge around for things to swallow. As and when they get enough to eat, they use up their surplus energy by wiggling in complicated patterns, making all sorts of noises by blowing air in and out of the input hole, and gathering together in groups to fight with other groups. In time, the tubes grow such anabundance of attached appliances that they are hardly recognizable as mere tubes, and they manage to do this in a staggering variety of forms. There is a vague rule not to eat tubes of your own form, but in general there is serious competition as to who is going to be the top type of tube. All this seems marvelously futile, and yet, when you begin to think about it, it begins to be more marvelous than futile. Indeed, it seems extremely odd.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["inspirational", "life", "self"],
    text: "\u201cIf you awaken from this illusion and you understand that black implies white, self implies other, life implies death (or shall I say death implies life?), you can feel yourself \u2013 not as a stranger in the world, not as something here on probation, not as something that has arrived here by fluke - but you can begin to feel your own existence as absolutely fundamental.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cHe who thinks that God is not comprehended, by him God is comprehended; but he who thinks that God is comprehended knows him not. God is unknown to those who know him, and is known to those who do not know him at all.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe centipede was happy, quite, Until a toad in fun Said, \u201cPray, which leg goes after which?\u201d This worked his mind to such a pitch, He lay distracted in a ditch, Considering how to run.\u201d    \u2015      Alan Wilson Watts,  The Way of Zen",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cI have always been fascinated by the law of reversed effort. Sometimes I call it the \u201cbackwards law.\u201d When you try to stay on the surface of the water, you sink; but when you try to sink, you float. When you hold your breath, you lose it\u2014which immediately calls to mind an ancient and much neglected saying, \u201cWhosoever would save his soul shall lose it.\u201d    \u2015      Alan W. Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cWe have made a problem for ourselves by confusing the intelligible with the fixed. We think that making sense out of life is impossible unless the flow of events can somehow be fitted into a framework of rigid forms. To be meaningful, life must be understandable in terms of fixed ideas and laws, and these in turn must correspond to unchanging and eternal realities behind the shifting scene. But if this what "making sense out of life" means, we have set ourselves the impossible task of making fixity out of flux.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety',
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["self-consciousness"],
    text: "\u201cI have no other self than the totality of things of which I am aware.\u201d    \u2015      Alan W. Watts,  The Way of Zen",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["fear", "future", "guilt", "heart", "now"],
    text: "\u201cNo work or love will flourish out of guilt, fear, or hollowness of heart, just as no valid plans for the future can be made by those who have no capacity for living now.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["definition"],
    text: "\u201cThe religious idea of God cannot do full duty for the metaphysical infinity.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWe think that the world is limited and explained by its past. We tend to think that what happened in the past determines what is going to happen next, and we do not see that it is exactly the other way around! What is always the source of the world is the present; the past doesn't explain a thing. The past trails behind the present like the wake of a ship and eventually disappears.\u201d    \u2015      Alan Wilson Watts,  What Is Zen?",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cFor every individual is a uniquemanifestation of the Whole, as every branch is a particular outreachingof the tree. To manifest individuality, every branch must have asensitive connection with the tree, just as our independently moving anddifferentiated fingers must have a sensitive connection with the wholebody. The point, which can hardly be repeated too often, is thatdifferentiation is not separation.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["alienation", "life", "play", "survival"],
    text: "\u201cHere is the vicious circle: if you feel separate from your organic life, you feel driven to survive; survival -going on living- thus becomes a duty and also a drag because you are not fully with it; because it does not quite come up to expectations, you continue to hope that it will, to crave for more time, to feel driven all the more to go on.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhat we see as death,empty space, or nothingness is only the trough between the crests of thisendlessly waving ocean. It is all part of the illusion that there shouldseem to be something to be gained in the future, and that there is anurgent necessity to go on and on until we get it. Yet just as there is notime but the present, and no one except the all-and-everything, there isnever anything to be gained\u2014though the zest of the game is to pretendthat there is.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cBelief clings, but faith lets go.\u201d    \u2015      Alan W. Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cInstant coffee, for example, is a well\u2013deserved punishment for being in a hurry to reach the future.\u201d    \u2015      Alan W. Watts,  Does It Matter?",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cTo travel is to be alive, but to get somewhere is to be dead, for as our own proverb says, \u201cTo travel well is better than to arrive.\u201d    \u2015      Alan W. Watts,  The Way of Zen",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cYet again, the more you strive for some kind of perfection or mastery\u2014inmorals, in art or in spirituality\u2014the more you see that you are playing ararified and lofty form of the old ego-game, and that your attainment ofany height is apparent to yourself and to others only by contrast withsomeone else's depth or failure.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cSociety is our extended mind and body.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cSo if you really go the whole way and see how you feel at the prospect of vanishing forever. Have all your efforts, and all your achievements, and all your attainments turning into dust and nothingness. What is the feeling? What happens to you? That's what it's all going to come to. And for some reason or other, we are supposed to find this depressing. Do you see in a way, how that is saying: the most real state is the state of nothing? But if somebody is going to argue that the basic reality is nothingness. Where does all this come from? Obviously from nothingness. Once again you get how it looks behind your eyes. You see?So in this way, by seeing that nothingness is the fundamental reality, and you see it's your reality. Then how can anything contaminate you? All the idea of you being scared, and put out and worried, and so on, this is nothing, it's a dream. Because you're really nothing. But this is most incredible nothing. So cheer up! You see?The essence of your mind is intrinsically pure. Pure means clear, void.See? If you think of this idea of nothingness as mere blankness, and you hold onto this idea of blankness then kind of grizzly about it, you haven't understood it. Nothingness is really like the nothingness of space, which contains the whole universe. All the sun and the stars and the mountains, and rivers, and the good men and bad men, and the animals, and insects, and the whole bit. All are contained in void. So out of this void comes everything and You Are IT. What else could You BE?\u201d    \u2015      Alan Watts,  The Essence of Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cHuman desire tends to be insatiable.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "alan-watts",
      "buddhism",
      "darkness",
      "fear",
      "healing",
      "hinduism",
      "just-love",
      "light",
      "love",
      "lovely",
      "loving",
      "realization",
      "spiritual",
      "unconditional-love",
    ],
    text: "\u201cWhen you find out that there was never anything in the dark side to be afraid of \u2026 Nothing is left but to love.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["body", "identity", "nature", "reality"],
    text: "\u201cNaturally, for a person who finds his identity in something other than his full organism is less than half a man. He is cut off from complete participation in nature. Instead of being a body, he 'has' a body. Instead of living and loving he 'has' instincts for survival and copulation.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWe are living in a culture entirely hypnotized by the illusion of time, in which the so-called present moment is felt as nothing but an infinitesimal hairline between an all-powerfully causative past and an absorbingly important future. We have no present. Our consciousness is almost completely preoccupied with memory and expectation. We do not realize that there never was, is, nor will be any other experience than present experience. We are therefore out of touch with reality. We confuse the world as talked about, described, and measured with the world which actually is. We are sick with a fascination for the useful tools of names and numbers, of symbols, signs, conceptions and ideas.\u201d    \u2015      Alan Wilson Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe startling truth is that our best efforts for civil rights, internationalpeace, population control, conservation of natural resources, andassistance to the starving of the earth\u2014urgent as they are\u2014will destroyrather than help if made in the present spirit. For, as things stand, wehave nothing to give. If our own riches and our own way of life are notenjoyed here, they will not be enjoyed anywhere else. Certainly theywill supply the immediate jolt of energy and hope that methedrine, andsimilar drugs, give in extreme fatigue. But peace can be made only bythose who are peaceful, and love can be shown only by those who love.No work of love will flourish out of guilt, fear, or hollowness of heart,just as no valid plans for the future can be made by those who have nocapacity for living now.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cFor unless one is able to live fully in the present, the future is a hoax. There is no point whatever in making plans for a future which you will never be able to enjoy. When your plans mature, you will still be living for some other future beyond. You will never, never be able to sit back with full contentment and say, \u201cNow, I\u2019ve arrived!\u201d Your entire education has deprived you of this capacity because it was preparing you for the future, instead of showing you how to be alive now.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["eastern-philosophy", "philosophy", "taoism", "wabi-sabi"],
    text: "\u201cTo Taoism that which is absolutely still or absolutely perfect is absolutely dead, for without the possibility of growth and change there can be no Tao. In reality there is nothing in the universe which is completely perfect or completely still; it is only in the minds of men that such concepts exist.\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["buddhism", "zen"],
    text: '\u201cHere\'s an example: someone says, "Master, please hand me the knife," and he hands them the knife, blade first. "Please give me the other end," he says. And the master replies, "What would you do with the other end?" This is answering an everyday matter in terms of the metaphysical.When the question is, "Master, what is the fundamental principle of Buddhism?" Then he replies, "There is enough breeze in this fan to keep me cool." That is answering the metaphysical in terms of the everyday, and that is, more or less, the principle zen works on. The mundane and the sacred are one and the same.\u201d    \u2015      Alan Watts,  What Is Zen?',
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cLife exists only at this very moment, and in this moment it is infinite and eternal. For the present moment is infinitely small; before we can measure it, it has gone, and yet it persists for ever.\u201d    \u2015      Alan W. Watts,  Become What You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cDo not let the rapidity with which these thoughts can change deceive you into feeling that you think them all at once.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["science"],
    text: "\u201cYou will never get to the irreducible definition of anything because you will never be able to explain why you want to explain, and so on. The system will gobble itself up.\u201d    \u2015      Alan Wilson Watts,  The Essential Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cNothing fails like success\u2014because the self-imposed task of oursociety and all its members is a contradiction: to force things to happenwhich are acceptable only when they happen without force.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["centeredness", "expectations", "philosophy", "zen"],
    text: "\u201cStay in the center, and you will be ready to move in any direction.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cBut nirvana is a radical transformation of how it feels to be alive: it feels as if everything were myself, or as if everything---including "my" thoughts and actions---were happening of itself. There are still efforts, choices, and decisions, but not the sense that "I make them"; they arise of themselves in relation to circumstances. This is therefore to feel life, not as an encounter between subject and object, but as a polarized field where the contest of opposites has become the play of opposites.\u201d    \u2015      Alan Wilson Watts,  Psychotherapy East and West',
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201conly doubtful truths need defense.\u201d    \u2015      Alan W. Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["ego", "loneliness", "metaphysics", "self"],
    text: '\u201cWe suffer from a hallucination, from a false and distorted sensation of our own existence as living organisms. Most of us have the sensation that "I myself" is a separate center of feeling and action, living inside and bounded by the physical body\u2014a center which "confronts" an "external" world of people and things, making contact through the senses with a universe both alien and strange. Everyday figures of speech reflect this illusion. "I came into this world." "You must face reality." "The conquest of nature." This feeling of being lonely and very temporary visitors in the universe is in flat contradiction to everything known about man (and all other living organisms) in the sciences. We do not "come into" this world; we come out of it, as leaves from a tree. As the ocean "waves," the universe "peoples." Every individual is an expression of the whole realm of nature, a unique action of the total universe. This fact is rarely, if ever, experienced by most individuals. Even those who know it to be true in theory do not sense or feel it, but continue to be aware of themselves as isolated "egos" inside bags of skin.\u201d    \u2015      Alan Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cPlaying a violin is, after all, only scraping a cat's entrails with horsehair.\u201d    \u2015      Alan Wilson Watts,  What Is Tao?",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe mystery of life is not a problem to be solved but a reality to be experienced.\u201d    \u2015      Alan Wilson Watts,  Cloud-hidden, Whereabouts Unknown",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe ego-self constantly pushes reality away. It constructs a future out of empty expectations and a past out of regretful memories.\u201d    \u2015      Alan W. Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cLook, here is a tree in the garden and every summer is produces apples, and we call it an apple tree because the tree "apples." That\'s what it does. Alright, now here is a solar system inside a galaxy, and one of the peculiarities of this solar system is that at least on the planet earth, the thing peoples! In just the same way that an apple tree apples!\u201d    \u2015      Alan Watts',
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cGenuine love comes from knowledge, not from a sense of duty or guilt.\u201d    \u2015      Alan W. Watts,  The Book: On the Taboo Against Knowing Who You Are",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cWhether we like it or not, change comes, and the greater the resistance, the greater the pain. Buddhism perceives the beauty of change, for life is like music in this: if any note or phrase is held for longer than its appointed time, the melody is lost. Thus Buddhism may be summed up in two phrases: \u201cLet go!\u201d and \u201cWalk on!\u201d Drop the craving for self, for permanence, for particular circumstances, and go straight ahead with the movement of life.\u201d    \u2015      Alan W. Watts,  Become What You Are",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: '\u201cWe therefore work, notfor the work\'s sake, but for money\u2014and money is supposed to get uswhat we really want in our hours of leisure and play. In the UnitedStates even poor people have lots of money compared with the wretchedand skinny millions of India, Africa, and China, while our middle andupper classes (or should we say "income groups") are as prosperous asprinces. Yet, by and large, they have but slight taste for pleasure. Moneyalone cannot buy pleasure, though it can help. For enjoyment is an artand a skill for which we have little talent or energy.\u201d    \u2015      Alan Wilson Watts,  The Book: On the Taboo Against Knowing Who You Are',
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cBelief\u2026is the insistence that the truth is what one would \u2018lief\u2019 or (will or) wish to be\u2026Faith is an unreserved opening of the mind to the truth, whatever it may turn out to be. Faith has no preconceptions; it is a plunge into the unknown. Belief clings, but faith let\u2019s go\u2026faith is the essential virtue of science, and likewise of any religion that is not self-deceptio\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe discovery of this reality is hindered rather than helped by belief, whether one believes in God or believes in atheism. We must here make a clear distinction between belief and faith, because, in general practice, belief has come to mean a state of mind which is almost the opposite of faith. Belief, as I use the word here, is the insistence that the truth is what one would \u201clief\u201d or wish it to be. The believer will open his mind to the truth on condition that it fits in with his preconceived ideas and wishes. Faith, on the other hand, is an unreserved opening of the mind to the truth, whatever it may turn out to be. Faith has no preconceptions; it is a plunge into the unknown. Belief clings, but faith lets go. In this sense of the word, faith is the essential virtue of science, and likewise of any religion that is not self-deception.\u201d    \u2015      Alan Wilson Watts,  Wisdom Of Insecurity: A Message for an Age of Anxiety",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cPeople can't be talked out of illusions. If a person believes that the earth is flat, you can't talk him out of that, he knows that it's flat. He'll go down to the window and see that its obvious, it looks flat. So the only way to convince him that it isn't is to say, \"Well let's go and find the edge\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [
      "anxiety",
      "eternal-life",
      "fear",
      "happiness",
      "meaning-of-life",
      "spirituality",
    ],
    text: '\u201cFor there is no joy in continuity, in the perpetual. We desire it only because the present is empty. A person who is trying to eat money is always hungry. When someone says, "Time to stop now!" he is in a panic because he has had nothing to eat yet, and wants more and more time to go on eating money, ever hopeful of satisfaction around the corner. We do not really want continuity, but rather a present experience of total happiness. The thought of wanting such an experience to go on and on is a result of being self-conscious in the experience, and thus incompletely aware of it. So long as there is the feeling of an "I" having this experience, the moment is not all. Eternal life is realized when the last trace of difference between "I" and "now" has vanished - when there is just this "now" and nothing else.By contrast, hell or "everlasting damnation" is not the everlastingness of time going on forever, but of the unbroken circle, the continuity and frustration of going round and round in pursuit of something which can never be attained. Hell is the fatuity, the everlasting impossibility, of self-love, self-consciousness, and seld-possession. It is trying to see one\u00b4s own eyes, hear one\u00b4s own ears, and kiss one\u00b4s own lips.\u201d    \u2015      Alan W. Watts,  The Wisdom of Insecurity: A Message for an Age of Anxiety',
  },
  {
    author: "Alan W. Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["zen-buddhism"],
    text: "\u201cFor if we open our eyes and see clearly, it becomes obvious that there is no other time than this instant, and that the past and the future are abstractions without any concrete reality.\u201d    \u2015      Alan W. Watts,  The Way of Zen",
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["presence", "present"],
    text: "\u201cHurrying and delaying are alike ways of trying to resist the present.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cAs you make more and more powerful microscopic instruments, the universe has to get smaller and smaller in order to escape the investigation. Just as when the telescopes become more and more powerful, the galaxies have to recede in order to get away from the telescopes. Because what is happening in all these investigations is this: Through us and through our eyes and senses, the universe is looking at itself. And when you try to turn around to see your own head, what happens? It runs away. You can't get at it. This is the principle. Shankara explains it beautifully in his commentary on the Kenopanishad where he says 'That which is the Knower, the ground of all knowledge, is never itself an object of knowledge.'[In this quote from 1973 Watts, remarkably, essentially anticipates the discovery (in the late 1990's) of the acceleration of the expansion of the universe.]\u201d    \u2015      Alan Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cThe true splendor of science is not so much that it names and classifies, records and predicts, but that it observes and desires to know the facts, whatever they may turn out to be. However much it may confuse facts with conventions, and reality with arbitrary divisions, in this openness and sincerity of mind it bears some resemblance to religion, understood in its other and deeper sense. The greater the scientist, the more he is impressed with his ignorance of reality, and the more he realizes that his laws and labels, descriptions and definitions, are the products of his own thought. They help him to use the world for purposes of his own devising rather than to understand and explain it. The more he analyzes the universe into infinitesimals, the more things he finds to classify, and the more he perceives the relativity of all classification. What he does not know seems to increase in geometric progression to what he knows. Steadily he approaches the point where what is unknown is not a mere blank space in a web of words but a window in the mind, a window whose name is not ignorance but wonder.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan W. Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: ["being", "buddhism", "nothingness", "spirituality", "void"],
    text: "\u201cIf we get rid of all wishful thinking and dubious metaphysical speculations, we can hardly doubt that \u2013 at a time not too distant \u2013 each one of us will simply cease to be. It won\u2019t be like going into darkness forever, for there will be neither darkness, nor time, nor sense of futility, nor anyone to feel anything about it. Try as best you can to imagine this, and keep at it. The universe will, supposedly, be going on as usual, but for each individual it will be as if it had never happened at all; and even that is saying too much, because there won\u2019t be anyone for whom it never happened. Make this prospect as real as possible: the one total certainty. You will be as if you had never existed, which was, however, the way you were before you did exist \u2013 and not only you but everything else. Nevertheless, with such an improbable past, here we are. We begin from nothing and end in nothing. You can say that again. Think it over and over, trying to conceive the fact of coming to never having existed. After a while you will begin to feel rather weird, as if this very apparent something that you are is at the same time nothing at all. Indeed, you seem to be rather firmly and certainly grounded in nothingness, much as your sight seems to emerge from that total blankness behind your eyes. The weird feeling goes with the fact that you are being introduced to a new common sense, a new logic, in which you are beginning to realize the identity of ku and shiki, void and form. All of a sudden it will strike you that this nothingness is the most potent, magical, basic, and reliable thing you ever thought of, and that the reason you can\u2019t form the slight idea of it is that it\u2019s yourself. But not the self you thought you were.\u201d    \u2015      Alan W. Watts",
  },
  {
    author: "Alan Wilson Watts,",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cOnly words and conventions can isolate us from the entirely undefinable something which is everything.\u201d    \u2015      Alan Wilson Watts,  The Wisdom of Insecurity",
  },
  {
    author: "Alan Wilson, Watts",
    image: "https://images.gr-assets.com/authors/1427892345p8/1501668.jpg",
    tags: [],
    text: "\u201cSo you see, if you become aware of the fact that you are all of your own body, and that thebeating of your heart is not just something that happens to you, but something you're doing,then you become aware also in the same moment and at the same time that you're not onlybeating your heart, but that you are shining the sun. Why? Because the process of yourbodily existence and its rhythms is a process, an energy system which is continuous with theshining of the sun, just like the East River, here, is a continuous energy system, and all thewaves in it are activities of the whole East River, and that's continuous with the AtlanticOcean, and that's all one energy system and finally the Atlantic ocean gets around to beingthe Pacific Ocean and the Indian Ocean, etc., and so all the waters of the Earth are acontinuous energy system. It isn't just that the East River is part of it. You can't draw anyline and say 'Look, this is where the East River ends and the rest of it begins,' as if you canin the parts of an automobile, where you can say 'This is definitely part of the generator,here, and over here is a spark plug.' There's not that kind of isolation between the elementsof nature.\u201d    \u2015      Alan Wilson, Watts",
  },
];
