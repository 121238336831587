import CssTricks from "./rssFeeds/cssTricks";
import SmashingMag from "./rssFeeds/smashingmag";
import AListApart from "./rssFeeds/aListApart";
import BrainPickings from "./rssFeeds/brainPickings";
import DevTo from "./rssFeeds/devto";


const RssFeedList = () => {
  return (
    <div>
      {/* <h2>RSS FEED LIST</h2> */}
      <div>
       <BrainPickings/> <br />
       <CssTricks /> <br />
        <SmashingMag/> <br />
        <AListApart/> <br />
        
        <DevTo/>
      </div>
    </div>
  );
};

export default RssFeedList;
