import useFetch from "../useFetch";

const PinboardList = () => {
  const sourceUrl = "https://shinytabworkers.bcoffield.workers.dev/pinboard/";
  const { data, isPending } = useFetch(sourceUrl);
   console.log("isPending:", isPending, "DATA: ", data);
  
 

  console.log(isPending);
  return (
    <div>
      <h4>Pinboard</h4>
      {data && !isPending ? (
        <div>
          {data.posts.slice(0, 5).map((entry) => (
            <ul key={entry.href}>
              <li>
                <a className="linkToStyle" target="_blank" href={entry.href} rel="noreferrer">
                  {entry.description}
                </a>
              </li>
            </ul>
          ))}
        </div>
      ) : (
        <div>Loading Pinboard. . .</div>
      )}
    </div>
  );
};

export default PinboardList;
