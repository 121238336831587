import useFetch from "../../useFetch";
const CssTricks = () => {
const {
  data,
  isPending,
  error,
} = useFetch("https://shinytabworkers.bcoffield.workers.dev/rss/csstricks/");



  return (
    <div>
      {data && !isPending ? (
        <div>
          <h4>CSS-Tricks</h4>
          {data.items.slice(0, 3).map((entry) => (
            <ul key={entry.pubDate}>
              <li>
                <a
                  className="linkToStyle"
                  href={entry.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {entry.title}
                </a>
              </li>
            </ul>
          ))}
        </div>
      ) : (
        <div>Loading CSS-Tricks. . . </div>
      )}
    </div>
  );
};

export default CssTricks;
