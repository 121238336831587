import { AlanWattsQuotes } from "../quotesData/AlanWatts";
// import { BashoQuotes } from "../quotesData/Basho";
// import { DostoevskyQuotes } from "../quotesData/Dostoevsky";
import { ThichNhatHanhQuotes } from "../quotesData/ThichNhatHanh";

const QuotesContainer = () => {
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
//   let ourRandom = randomNumber(0, AlanWattsQuotes.length)
  return (
    <div>
      {/* <h2>Quotes Container</h2> */}
      <div id="alanWattsQuote">
        {AlanWattsQuotes[randomNumber(0, AlanWattsQuotes.length)].text}
      </div>
      <br />
      {/* <div>{BashoQuotes[randomNumber(0, BashoQuotes.length)].text}</div>
      <br />
      <div>{DostoevskyQuotes[randomNumber(0, DostoevskyQuotes.length)].text}</div>
      <br /> */}
      {/* <div id="thichNhatHanhQuote">
        {ThichNhatHanhQuotes[randomNumber(0, ThichNhatHanhQuotes.length)].text}
      </div> */}
    </div>
  );
};;

export default QuotesContainer;
