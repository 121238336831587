import useFetch from "../../useFetch";
const AListApart = () => {
  const { data, isPending, error } = useFetch(
    "https://shinytabworkers.bcoffield.workers.dev/rss/alistapart/"
  );

  return (
    <div>
      {data && !isPending ? (
        <div>
          <h4>A List Apart</h4>
          {data.items.slice(0, 3).map((entry) => (
            <ul key={entry.pubDate}>
              <li>
                <a className="linkToStyle" href={entry.link} target="_blank" rel="noreferrer"> 
                  {entry.title}
                </a>
              </li>
            </ul>
          ))}
        </div>
      ) : (
        <div>Loading A List Apart. . . </div>
      )}
    </div>
  );
};

export default AListApart;
